.Footer-Container{
    height: 90px;
    padding: 0px 83px;
    .Line{
        content: "";
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }
    a{
        color: black;
        p{
            font-family: DinLight;
            font-family: 14px;
        }
    }
}
@media (max-width: 768px) {
    .Footer-Container{
        padding: 20px 21px;
        a{
            font-size: 16px;
        }
    }
}

@media (min-width: 2048px) {
    .Footer-Container{
        a{
            font-size: 36px;
        }
    }
}