.News-Container {
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;

    .News-Section-1 {
        height: 110vh;
        padding: 100px 0px;

        img {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .News-Content {
            display: flex;
            flex-direction: column;
            display: block;
            position: absolute;
            z-index: 1;
            color: white;
            top: 50%;
            left: 8%;
            font-family: DinLight;

            h1 {
                font-family: DinMedium;
                font-size: 40px;
            }

            p {
                max-width: 600px;
                font-size: 20px;
                text-align: justify;
                margin-top: 30px;
            }

            span {
                font-size: 20px;
            }

            .Button-Container {
                display: flex;
                margin-top: 20px;

                button {
                    background-color: transparent;
                    width: 100%;
                    max-width: 140px;
                    border: 1px solid white;
                    margin-right: 20px;
                }
            }
        }
    }

    .News-Section-2 {
        height: auto;
        padding: 120px 84px;

        .Title-Section {
            h2 {
                font-family: DinMedium;
                font-weight: 700;
            }
        }

        .Card-Section { 
            margin-top: 50px;
            height: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            justify-items: center;
            .Card {
                width: 100%;
                max-width: 360px;
                display: flex;
                flex-direction: column;
                margin-bottom: 50px;

                .Image-Container {
                    width: 100%;
                    height: 100%;
                    max-height: 250px;

                    img {
                        width: 100%;
                        height: 100%;
                        max-width: 360px;
                        max-height: 250px;
                        object-fit: cover;
                    }
                }

                .Title-Container {
                    margin-top: 30px;

                    a {
                        font-family: DinMedium;
                        font-size: 20px;
                        color: black;
                        text-decoration: none;

                        h2 {
                            font-size: 20px;
                        }

                    }

                    h2:hover {
                        text-decoration: underline;
                        cursor: pointer;
                        ;
                    }
                }
            }
        }
    }

    .News-Section-3 {
        display: flex;
        flex-direction: column;
        padding: 0px 82px 50px 82px;

        .Line {
            content: "";
            width: 100%;
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }

        .Event-Container {
            margin-top: 50px;
            height: auto;

            h2 {
                font-family: DinMedium;
                font-weight: 700;
            }

            .Event-Content {
                display: flex;
                padding: 50px 0px;
                justify-content: space-between;

                .Card {
                    max-width: 400px;
                    display: flex;

                    h3 {
                        font-size: 24px
                    }

                    img {
                        max-width: 27px;
                        max-height: 27px;
                        margin-top: 5px;
                        margin-right: 20px;
                    }

                    p {
                        margin-top: 15px;
                        text-align: justify;
                    }
                }
            }
        }
    }

}

@media (max-width: 768px) {
    .News-Container {
        padding: 0px;
        overflow-y: hidden;
        .News-Section-1 {
            padding: 0px;

            img {
                object-fit: cover;
            }

            .News-Content {
                padding: 0px 21px 0px 0px;
                top: 35%;

                h1 {
                    font-size: 40px;
                }

                p {
                    font-size: 18px;
                }
            }
        }

        .News-Section-2 {
            padding: 0px 21px;

            .Card-Section {
                 grid-template-columns: repeat(2, 1fr);
                 grid-template-rows: repeat(2, 1fr);
                height: 100%;

                .Card {
                    max-width: 150px;

                    .Image-Container {
                        max-height: 130px;

                        img {
                            max-width: 150px;
                            max-height: 130px;
                        }
                    }

                    .Title-Container {
                        margin-top: 10px;

                        a {
                            h2 {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

        .News-Section-3 {
            padding: 50px 21px 50px 21px;
            width: 100%;
            .Event-Container {
                .Event-Content {
                    flex-direction: column;

                    .Card {
                        margin-bottom: 20px;
                        max-width: 350px;
                    }
                }
            }
        }
    }
}

@media (min-width: 2048px) {
    .News-Container {
        .News-Section-1 {
            .News-Content {
                h1 {
                    font-size: 70px;
                }

                p {
                    font-size: 32px;
                }

                span {
                    font-size: 32px;
                }

                .Button-Container {
                    button {
                        max-width: 210px;
                        font-size: 26px;
                    }
                }
            }
        }

        .News-Section-2 {
            .Title-Section {
                h2 {
                    font-size: 50px;
                }
            }

            .Card-Section {
                height: 100vh;
                .Card {
                    max-width: 600px;

                    .Image-Container {
                        max-height: 350px;

                        img {
                            max-height: 350px;
                            max-width: 600px;
                        }
                    }

                    .Title-Container {
                        a {
                            h2 {
                                font-size: 32px;
                            }
                        }
                    }
                }
            }
        }

        .News-Section-3 {
            .Event-Container {
                h2 {
                    font-size: 50px;
                }

                .Event-Content {
                    .Card {
                        max-width: 520px;
                        h3 {
                            font-size: 36px;
                        }
                        span{
                            font-size: 28px;
                        }
                        p{
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
}


@media (min-width: 3024px) {
    .News-Container{
        .News-Section-2{
            .Title-Section{
                h2{
                    font-size: 80px;
                }
            }
            .Card-Section{
                height: 100%;
                .Card{
                    max-width: 800px;
                    .Image-Container{
                        max-height: 500px;
                        img{
                            max-width: 800px;
                            max-height: 500px;
                        }
                    }
                    .Title-Container{
                        a{
                            h2{
                                font-size: 42px;
                            }
                        }
                    }
                }
            }
        }
    }
}