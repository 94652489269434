.Home-Container {
    width: 100%;
    padding-top: 100px;
    height: auto;
    display: flex;
    flex-direction: column;

    .Home-Section-1 {
        height: 100vh;
        padding: 0px 86px 0px 86px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
            position: relative;
            font-family: DinMedium;
            font-size: 90px;
            margin: 0px;

            // opacity: 0;
            // transform: translateY(100px);
            span {
                display: block;
            }
        }

        p {
            font-family: DinLight;
            font-size: 24px;
            margin-top: 30px;

            // transform: translateY(100px);
            // opacity: 0;
        }
    }

    .Home-Section-2 {
        height: 100vh;
        display: flex;
        flex-direction: column;
        margin: 0px 86px;
        justify-content: center;

        .line {
            content: "";
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }

        .Content-Section-2 {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 50px 0px;
            p {
                margin: 0px 0px;
                width: 50%;
                font-family: DinLight;
                font-size: 20px;

                span {
                    display: block;
                    margin-top: 40px;
                }

                a {
                    color: black;
                    text-decoration: none;
                    font-family: DinRegular;
                    span{
                        text-decoration: underline;
                    }
                }
            }

            .img {
                height: 75%;
            }
        }

    }

    .Home-Section-3 {
        width: 100%;
        height: 100vh;
        display: flex;

        .content-sec-3 {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            position: relative;

            .Section-3bg {
                width: 70%;
                height: 100%;
                position: absolute;
            }

            .Section-3-Text {
                width: 80%;
                height: 100%;
                font-family: DinLight;
                top: 60%;
                left: 20%;
                display: flex;
                flex-direction: column;
                position: absolute;
                color: white;

                span {
                    display: block;
                }

                a {
                    color: white;
                }
            }
        }
    }

    .Home-Section-4 {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        padding: 0px;
        padding: 150px 86px;

        .line {
            content: "";
            width: 100%;
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }

        .Section-4-Content {
            display: flex;
            width: 100%;
            padding: 100px 0px;

            .Left {
                width: 100%;
                height: 100%;
                font-size: 32px;
                font-family: DinLight;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-left: 86px;

                h3 {
                    margin: 0px;
                }
            }

            .Right {
                width: 100%;
                height: 100%;
                font-family: DinLight;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-right: 86px;

                p {
                    font-size: 20px;
                    margin: 0px;
                }

                a {
                    margin-top: 132px;
                    color: black;
                    font-size: 20px;
                }
            }
        }
    }

    .Home-Section-5 {
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding: 0px 83px;
        font-family: DinLight;
        justify-content: flex-start;

        .Section-5-Middle {
            display: flex;
            width: 100%;
            margin-top: 20px;
            justify-content: space-between;

            .Card {
                width: 100%;
                max-width: 360px;
                display: flex;
                flex-direction: column;
                margin-bottom: 50px;

                .Image-Container {
                    width: 100%;
                    height: 100%;
                    max-height: 250px;

                    img {
                        width: 100%;
                        height: 100%;
                        max-width: 360px;
                        max-height: 250px;
                        object-fit: cover;
                    }
                }

                .Title-Container {
                    margin-top: 30px;

                    a {
                        font-family: DinMedium;
                        font-size: 20px;
                        color: black;
                        text-decoration: none;

                        h2 {
                            font-size: 20px;
                        }

                    }

                    h2:hover {
                        text-decoration: underline;
                        cursor: pointer;
                        ;
                    }
                }
            }
        }

        .Section-5-Bottom {
            margin: 30px 0px;

            a {
                color: black;
            }
        }
    }
}

@media (max-width: 768px) {
    .Home-Container {
        .Home-Section-1 {
            padding: 0px 21px;

            h1 {
                font-size: 55px;
            }

            p {
                font-size: 24px;
            }
        }

        .Home-Section-2 {
            height: auto;
            margin: 0px;
            padding: 0px 21px;

            .Content-Section-2 {
                flex-direction: column-reverse;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                }

                p {
                    width: 100%;
                    font-size: 18px;
                    text-align: justify;
                }
            }
        }

        .Home-Section-3 {
            height: 50vh;

            .content-sec-3 {
                .Section-3bg {
                    height: auto;
                    width: 100%
                }

                .Section-3-Text {
                    top: 30%;
                    left: 5%;

                    h2 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }

        .Home-Section-4 {
            padding: 0px 21px;
            height: auto;

            .Section-4-Content {
                padding: 50px 0px;
                flex-direction: column;
                .Left {
                    padding-left: 0px;
                    margin-bottom: 16px;
                    h3 {
                        font-size: 20px;
                    }
                }

                .Right {
                    padding-right: 0px;

                    p {
                        font-size: 14px;
                    }

                    a {
                        margin-top: 42px;
                        font-size: 14px;
                    }
                }
            }
        }

        .Home-Section-5 {
            padding: 50px 21px;
            height: auto;
            .Section-5-Middle {
                flex-direction: column;
                align-items: center;

                .Card {
                    width: 300px;
                    margin-top: 30px;

                    img {
                        width: 100%;
                    }

                    h3 {
                        font-size: 18px;
                    }
                }
            }

            .Section-5-Bottom {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media (min-width: 2048px) {
    .Home-Container {
        .Home-Section-1 {
            h1 {
                font-size: 140px;
            }

            p {
                font-size: 46px;
            }
        }

        .Home-Section-2 {
            padding: 100px 86px;

            .Content-Section-2 {
                p {
                    font-size: 40px;
                }

                .img {
                    height: 60%;
                    width: 35%;
                    margin-top: 100px;
                }
            }
        }

        .Home-Section-3 {
            padding: 100px 0px;

            .content-sec-3 {
                .Section-3-Text {
                    width: unset;
                    height: unset;
                    top: 50%;

                    h2 {
                        font-size: 70px;
                    }

                    p {
                        font-size: 40px;
                    }

                    a {
                        font-size: 36px;
                    }
                }
            }
        }

        .Home-Section-4 {
            padding: 100px 86px;

            .Section-4-Content {
                .Left {
                    h3 {
                        font-size: 46px;
                        font-family: DinMedium;
                    }
                }

                .Right {
                    p {
                        font-size: 40px;

                    }

                    a {
                        font-size: 38px;
                    }
                }
            }
        }

        .Home-Section-5 {

            .Section-5-Top {
                h2 {
                    font-size: 54px;
                }
            }

            .Section-5-Middle {
                .Card {
                    max-width: 430px;

                    .Image-Container {
                        max-height: 300px;

                        img {
                            max-height: 300px;
                            max-width: 430px;
                        }
                    }
                    .Title-Container{
                        h2{
                            font-size: 30px;
                        }
                    }
                }
            }
            .Section-5-Bottom{
                a{
                    span{
                        font-size: 36px;
                    }
                }
            }
        }
    }
}