.People-Container {
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;

    .People-Section-1 {
        height: 100vh;
        padding-top: 100px;

        img {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .People-Content {
            display: flex;
            flex-direction: column;
            display: block;
            position: absolute;
            z-index: 1;
            color: white;
            top: 50%;
            left: 8%;
            font-family: DinLight;

            h1 {
                font-size: 40px;
                font-family: DinMedium;
            }

            p {
                font-size: 20px;
                margin-top: 30px;

                span {
                    display: block;
                }
            }
        }
    }

    .People-Section-2 {
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 200px 110px;
        font-family: DinLight;

        .People-Title {
            h2 {
                font-size: 32px;
            }
        }

        .Line {
            content: "";
            width: 100%;
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.2);
        }

        .Biography {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 60px 0px;
            font-family: DinLight;

            h3 {
                font-size: 40px;
                font-weight: 300;
                margin: 0px;
            }

            p {
                margin-top: 60px;
                font-size: 20.2px;
                text-align: justify;

                span {
                    display: block;
                    margin-top: 20px;
                }
            }

            .Left {
                width: 30%;

                img {
                    width: 100%;
                }
            }

            .Right {
                width: 65%;
                display: flex;
                flex-direction: column;
                align-content: space-between;

                .People-Contact {
                    span{
                        font-size: 20px;
                        margin-right: 5px;
                    }
                    a {
                        font-size: 20px;
                        color:black
                    }
                }
            }
        }
    }
}

@media(max-width: 768px) {
    .People-Container {
        .People-Section-1 {
            padding: 0px;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .People-Section-2 {
            padding: 40px 21px;

            .Biography {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                
                .Left {
                    width: 100%;
                }

                .Right {
                    width: 100%;
                    h3{
                        margin-top: 15px;
                    }
                    p{
                        margin-top: 35px;
                    }
                    .People-Contact{
                        margin-top: 20px;
                        span{ 
                        font-size: 18px;
                        }
                        a{
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 2048px) {
    .People-Container{
        .People-Section-1{
            .People-Content{
                
            h1 {
                font-size: 70px
            }

            p {
                font-size: 36px;
            }
            }
        }
        .People-Section-2{
            .People-Title{
                h2{
                    font-size: 50px;
                }
            }
            .Biography{
                .Right{
                    h3{
                        font-size: 48px;
                    }
                    p{
                        font-size: 32px;
                    }
                    .People-Contact{
                        span{
                            font-size: 32px;
                        }
                        a{
                            font-size: 32px;
                        }
                    }
                }
            }
        }
    }
}